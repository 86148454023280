<template>
  <div class="notice">
    <Title style="height: 800px" :chs="chs" :en="en" :flag="$route.path" />

    <div class="list">
      <div class="subtitle">
        <img src="../assets/img/new.png" width="68" height="60" />
        <span>最新</span>
      </div>
      <div class="news">
        <template v-if="hots.length > 0">
          <div v-for="(item, index) in hots" :key="index" class="item" :class="`item_${index + 1}`" @click="openUrl(item)">
            <img :src="item.picUrl" />
            <div class="content">
              <div class="item_name text_overflow1">{{ item.title }}</div>
              <div class="desc">{{ item.desc }}</div>
              <div class="item_time">{{ item.createdTime }}</div>
            </div>
            <i v-if="index + 1 > 1" class="el-icon-arrow-right"></i>
          </div>
        </template>
      </div>
      <el-empty v-if="hots.length == 0" description="暂无内容"></el-empty>

      <div class="subtitle">
        <img src="../assets/img/all.png" width="68" height="60" />
        <span>全部</span>
      </div>
      <div class="alls">
        <template v-if="list.length > 0">
          <div v-for="(item, index) in list" :key="index" class="item" :class="`item_${index + 1}`" @click="openUrl(item)">
            <img :src="item.picUrl" width="212" height="127" />
            <div class="content">
              <div class="item_name text_overflow1">{{ item.title }}</div>
              <div class="desc">{{ item.desc }}</div>
              <div class="item_time">{{ item.createdTime }}</div>
            </div>
            <i class="el-icon-arrow-right"></i>
          </div>
        </template>
      </div>
      <el-empty v-if="list.length == 0" description="暂无内容"></el-empty>

      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      chs: "公告信息",
      en: "Announcement information",
      hots: [],
      list: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.getNewList()
    this.getAllList()
  },
  methods: {
    getNewList() {
      this.$axios
        .get(this.$api.selectNoticeInfoNewList)
        .then(res => {
          this.hots =
            res.result.map(e => {
              return {
                ...e,
                desc: this.formatRichText(e.content),
              }
            }) || []
        })
        .catch(err => {
          this.$message.error(err.desc)
        })
    },
    getAllList() {
      this.$axios
        .get(this.$api.selectNoticeInfoAllList, {
          params: {
            page: this.currentPage,
            pageSize: this.pageSize,
          },
        })
        .then(res => {
          this.list =
            res.result.list.map(e => {
              return {
                ...e,
                desc: this.formatRichText(e.content),
              }
            }) || []
          this.totalItemsCount = res.result.totalCount
        })
        .catch(err => {
          this.$message.error(err.desc)
        })
    },
    formatRichText(html) {
      const replacedHtml = html.replace(/<img[^>]*>/g, "[图片]")
      const tempDiv = document.createElement("div")
      tempDiv.innerHTML = replacedHtml
      return tempDiv.textContent || tempDiv.innerText
    },
    openUrl(e) {
      let { id } = e
      this.$router.push({ name: "detail", query: { id } })
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.pageSize = e
      this.getSysUserList()
    },
    // 换页
    handleCurrentChange(e) {
      this.currentPage = e
      this.getSysUserList()
    },
  },
}
</script>

<style scoped lang="scss">
.notice {
  height: 100%;
  background-color: #eef4ff;

  .list {
    padding: 80px;
    transform: translateY(-50px);

    .subtitle {
      display: flex;
      align-items: center;
      color: #171717;
      font-size: 46px;

      span {
        margin-left: 20px;
      }
    }

    .news {
      display: grid;
      grid-template-columns: 560px 1fr;
      grid-template-rows: repeat(3, 1fr);
      gap: 40px;
      margin: 30px 0 80px;

      .item {
        padding: 30px;
        background-color: #fff;
        border-radius: 10px;
        box-sizing: border-box;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }

        img {
          width: 212px;
          height: 127px;
          border-radius: 6px;
        }
      }

      .item:first-child {
        grid-row-start: 1;
        grid-row-end: 4;
        display: flex;
        flex-direction: column;
        font-size: 20px;
        color: #171717;

        img {
          width: 500px;
          height: 300px;
          margin-bottom: 20px;
        }

        .content {
          flex: 1;

          div {
            font-size: 18px;
            margin-bottom: 20px;
          }

          .name {
            font-size: 20px;
            font-weight: bold;
          }

          .desc {
            display: -webkit-box;
            word-break: break-all;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 7;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 168px;
          }

          .item_time {
            margin-bottom: 0;
          }
        }
      }

      .item:not(:first-child) {
        display: flex;
        align-items: center;

        .content {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 127px;
          margin-left: 20px;

          .desc {
            display: -webkit-box;
            word-break: break-all;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .alls {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 40px;
      margin: 30px 0 60px;

      .item {
        display: flex;
        align-items: center;
        padding: 30px;
        background-color: #fff;
        border-radius: 10px;
        box-sizing: border-box;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }

        img {
          border-radius: 6px;
        }

        .content {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 127px;
          margin-left: 20px;

          .desc {
            display: -webkit-box;
            word-break: break-all;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .item_name {
      font-size: 20px;
      font-weight: bold;
    }

    .item_time {
      color: #666666;
      font-size: 16px;
      margin-bottom: 0;
    }

    .el-icon-arrow-right {
      width: 30px;
      text-align: right;
      color: #999999;
      font-size: 20px;
      font-weight: bold;
    }
  }
}
</style>
